// RepresentativeFilter.js
import React from "react";

import { UserCircle } from "lucide-react";

export function RepresentativeFilter({
  representatives,
  selectedRepresentative,
  onRepresentativeChange,
}) {
  return (
    <div className="card p-3 mb-3">
      <div className="form-group">
        <div style={{ display: "flex", flexDirection: "row", alignItems:"center" }}>
          <UserCircle
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            size={20}
          />
          &nbsp;
          <label htmlFor="representative" style={{fontFamily:"system-ui"}}>Representative</label>
        </div>
        <select
          id="representative"
          className="form-control"
          value={selectedRepresentative}
          onChange={(e) => onRepresentativeChange(e.target.value)}
          style={{fontFamily:"system-ui"}}
        >
          {representatives.map((rep) => (
            <option key={rep} value={rep}>
              {rep}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
