// RoleFilter.js
import React from "react";
import { Briefcase } from "lucide-react";
export function RoleFilter({ roles, selectedRole, onRoleChange }) {
  return (
    <div className="card p-3 mb-3">
      <div className="form-group">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Briefcase
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            size={20}
          />
          &nbsp;
          <label htmlFor="role" style={{fontFamily:"system-ui"}}>Role</label>
        </div>
        <select
          id="role"
          className="form-control"
          value={selectedRole}
          onChange={(e) => onRoleChange(e.target.value)}
          style={{fontFamily:"system-ui"}}
        >
          {roles.map((role) => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
