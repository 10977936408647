// ZoomAnalysis.js

import React, { useState, useEffect, useMemo } from 'react';
import { SessionCard } from './component/SessionCard';
import { SessionDetail } from './component/SessionDetail';
import { DateRangeFilter } from './component/DateRangeFilter';
import { RepresentativeFilter } from './component/RepresentativeFilter';
import { RoleFilter } from './component/RoleFilter';
import ZoomPagination from './component/ZoomPagination';
import axios from 'axios';

function ZoomAnalysis() {
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedRepresentative, setSelectedRepresentative] = useState('All Representatives');
  const [selectedRole, setSelectedRole] = useState('All');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Function to parse flag strings into structured objects
  const parseFlags = (flagString) => {
    if (!flagString) return [];
    const flagEntries = flagString.split('\n\n'); // Split by double newline to separate each flag
    const flags = flagEntries.map((entry) => {
      const lines = entry.split('\n').map((line) => line.trim());
      const flag = {};
      lines.forEach((line) => {
        if (line.startsWith('- Category:')) {
          flag.category = line.replace('- Category:', '').trim();
        } else if (line.startsWith('Text:')) {
          flag.text = line.replace('Text:', '').trim();
        } else if (line.startsWith('Context:')) {
          flag.context = line.replace('Context:', '').trim();
        }
      });
      return flag;
    });
    return flags;
  };

  // Fetch data from the API
  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get('https://zoom.kuru.ai/data');
        const data = response.data;

        // Process and normalize data
        const normalizedData = data.map((session) => {
          // Prepare participants list
          const participantsArray = session.participants
            ? session.participants.split(',').map((p) => p.trim())
            : [];

          // Remove duplicates
          const uniqueParticipants = Array.from(new Set(participantsArray));

          // For title, remove strategist
          const participantsWithoutStrategist = uniqueParticipants.filter(
            (p) => p.toLowerCase() !== session.strategist.toLowerCase()
          );

          // Capitalize participant names for title
          const capitalizedParticipants = participantsWithoutStrategist.map((name) =>
            name
              .split(' ')
              .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
              .join(' ')
          );

          // Create session title
          const title =
            capitalizedParticipants.length > 0
              ? `${capitalizedParticipants.join(', ')} Property Session`
              : 'Property Session';

          return {
            ...session,
            id: session.id.toString(),
            date: session.session_date,
            representative: {
              name: session.strategist || 'Unknown',
              email: session.strategist_email,
              role: session.representative_type || 'Unknown',
            },
            participants: uniqueParticipants.map((name) => ({ name })), // Include strategist
            duration: session.session_duration,
            rating: session.rating_out_of_5 ? session.rating_out_of_5 * 2 : null, // Convert to out of 10
            ratingJustification: session.rating_justification,
            leadIntentScore: session.lead_intent_score,
            leadIntentJustification: session.lead_intent_score_justification,
            summary: session.summary_of_session,
            sessionStrengths: session.strengths_of_the_session
              ? session.strengths_of_the_session.split('\n')
              : [],
            sessionWeaknesses: session.weaknesses_of_the_session
              ? session.weaknesses_of_the_session.split('\n')
              : [],
            recommendations: session.recommendation_for_improvement
              ? session.recommendation_for_improvement.split('\n')
              : [],
            conclusion: session.conclusion,
            flags: {
              red: parseFlags(session.red_flags),
              orange: parseFlags(session.orange_flags),
              yellow: parseFlags(session.yellow_flags),
              green: parseFlags(session.green_flags),
            },
            topics: [], // Add if you have topics data
            videoLink: session.zoom_link,
            title: title, // Updated title
          };
        });

        setSessions(normalizedData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, []);

  // Filter sessions based on filters
  const filteredSessions = useMemo(() => {
    return sessions.filter((session) => {
      const sessionDate = new Date(session.date);
      const matchesDate =
        (!startDate || sessionDate >= new Date(startDate)) &&
        (!endDate || sessionDate <= new Date(endDate));

      const matchesRepresentative =
        selectedRepresentative === 'All Representatives' ||
        (session.representative.name &&
          session.representative.name === selectedRepresentative);

      const matchesRole =
        selectedRole === 'All' ||
        (session.representative.role &&
          session.representative.role === selectedRole);

      return matchesDate && matchesRepresentative && matchesRole;
    });
  }, [sessions, startDate, endDate, selectedRepresentative, selectedRole]);

  const totalItems = filteredSessions.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const currentSessions = useMemo(() => {
    const indexOfLastSession = currentPage * itemsPerPage;
    const indexOfFirstSession = indexOfLastSession - itemsPerPage;
    return filteredSessions.slice(indexOfFirstSession, indexOfLastSession);
  }, [filteredSessions, currentPage, itemsPerPage]);

  const currentSession = sessions.find((s) => s.id === selectedSession);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const representatives = useMemo(() => {
    const repsSet = new Set(sessions.map((s) => s.representative.name).filter((name) => name));
    const reps = Array.from(repsSet);
    return ['All Representatives', ...reps];
  }, [sessions]);

  const roles = ['All', 'Strategist', 'Specialist', 'Investment Leading Manager'];

  return (
    <div className="container-fluid py-4">
      {!selectedSession ? (
        <>
          <div className="row mb-4">
            <div className="col-md-6">
              <DateRangeFilter
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={(date) => {
                  setStartDate(date);
                  setCurrentPage(1); 
                }}
                onEndDateChange={(date) => {
                  setEndDate(date);
                  setCurrentPage(1); 
                }}
              />
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-sm-6">
                  <RepresentativeFilter
                    representatives={representatives}
                    selectedRepresentative={selectedRepresentative}
                    onRepresentativeChange={(rep) => {
                      setSelectedRepresentative(rep);
                      setCurrentPage(1); 
                    }}
                  />
                </div>
                <div className="col-sm-6">
                  <RoleFilter
                    roles={roles}
                    selectedRole={selectedRole}
                    onRoleChange={(role) => {
                      setSelectedRole(role);
                      setCurrentPage(1); 
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <div className="text-center">Loading...</div>
          ) : error ? (
            <div className="alert alert-danger">Error: {error}</div>
          ) : (
            <>
              <div className="row">
                {currentSessions.map((session) => (
                  <div className="col-md-12 mb-4" key={session.id}>
                    <SessionCard
                      session={session}
                      onClick={setSelectedSession}
                    />
                  </div>
                ))}
              </div>

              <ZoomPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </>
          )}
        </>
      ) : currentSession ? (
        <SessionDetail
          session={currentSession}
          onBack={() => setSelectedSession(null)}
        />
      ) : null}
    </div>
  );
}

export default ZoomAnalysis;
