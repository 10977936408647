// SessionCard.js
import React from "react";
import { formatDistanceToNow } from "date-fns";
import { Users, Clock, ChevronRight, UserCircle } from "lucide-react";

export function SessionCard({ session, onClick }) {
  // Calculate days ago
  const sessionDate = new Date(session.date);
  const daysAgo = formatDistanceToNow(sessionDate, { addSuffix: true });

  return (
    <div
      className="card mb-3"
      onClick={() => onClick(session.id)}
      style={{ cursor: "pointer" }}
    >
      <div className="card-body">
        <h5 className="card-title" style={{ fontWeight: 500 }}>
          {session.title}
        </h5>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            marginTop: 10,
          }}
        >
          <h6
            className="card-title mb-2"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              margin: 0,
              fontFamily:'system-ui'
            }}
          >
            <UserCircle
              size={20}
              className="text-indigo-600"
              style={{ color: "#1f9ad6" }}
            />
            &nbsp;
            {session.representative.name}
          </h6>
          <p
            className="card-subtitle mb-2 text-muted"
            style={{
              fontSize: 14,
              fontWeight: 400,
              margin: 0,
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              fontFamily:'system-ui'
            }}
          >
            &nbsp;[{session.representative.role}]&nbsp;&nbsp;•&nbsp;&nbsp;
            {daysAgo}&nbsp;&nbsp;&nbsp;&nbsp;
            <Users size={16} />
            &nbsp;&nbsp;
            {session.participants.length}
            &nbsp;&nbsp;Participants&nbsp;&nbsp;&nbsp;&nbsp;
            <Clock size={16} />
            &nbsp;&nbsp;{session.duration} minutes
          </p>
        </div>
        <p
          className="mb-3 card-text"
          style={{
            color: "black",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            marginTop: 10,
            fontWeight: 400,
            fontFamily:'system-ui'
          }}
        >
          {session.summary}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{ width: 7, height: 7, backgroundColor: "#22c55e", borderRadius:"50%" }}
          ></div>&nbsp;
          <p
            className="card-text"
            style={{ color: "black", fontWeight: 400, fontSize: 14, fontFamily:'system-ui' }}
          >
            Rating: {session.rating ? `${session.rating}/10` : "N/A"}
          </p>
        </div>
      </div>
    </div>
  );
}
