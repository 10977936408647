import "./App.css";
import Login from "./Pages/Login";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./Pages/Error";
import Root from "./Pages/Root";
import Dashboard from "./Pages/Dashboard";
import User from "./Pages/User";
import Blog from "./Pages/Blog";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import Leads from "./Pages/Leads";
import Settings from "./Pages/Settings";
import Inbox from "./Pages/Inbox";
import ZoomAnalysis from "./Pages/ZoomAnalysis";
import ZoomRoot from "./Pages/ZoomRoot";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/staff",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy-policy",
    element: <Privacy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/terms-conditions",
    element: <Terms />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "users",
        element: <User />,
      },
      {
        path: "blog",
        element: <Blog />,
      },
      // {
      //   path: "chat",
      //   element: <Chat />,
      // },
      {
        path: "inbox",
        element: <Inbox />,
      },
      {
        path: "leads",
        element: <Leads />,
      },
      {
        path: "zoom-analysis",
        element: <ZoomAnalysis />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
  {
    path: "/zoom-dashboard",
    element: <ZoomRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <ZoomAnalysis />,
      },
    ],
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
