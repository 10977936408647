// DateRangeFilter.js
import React from 'react';

export function DateRangeFilter({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) {
  return (
    <div className="card p-3 mb-3">
      <div className="form-row" style={{display:"flex", flexDirection:"row"}}>
        <div className="form-group col-md-6">
          <label htmlFor="startDate" style={{fontFamily:"system-ui"}}>From Date</label>
          <input
            type="date"
            id="startDate"
            className="form-control"
            value={startDate}
            onChange={(e) => onStartDateChange(e.target.value)}
          />
        </div>
        &nbsp; &nbsp;
        <div className="form-group col-md-6">
          <label htmlFor="endDate" style={{fontFamily:"system-ui"}}>To Date</label>
          <input
            type="date"
            id="endDate"
            className="form-control"
            value={endDate}
            onChange={(e) => onEndDateChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
