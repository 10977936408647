// SessionDetail.js
import React from "react";
import {
  ArrowLeft,
  Star,
  Target,
  Brain,
  Link as LinkIcon,
  User,
  Mail,
  Users,
  Clock,
  Video,
  Calendar,
  Briefcase,
  CircleCheckBig,
  CircleX,
} from "lucide-react";
import { formatDistanceToNow, format } from "date-fns";
import { FlagSection } from "./FlagSection";

export function SessionDetail({ session, onBack }) {
  return (
    <div>
      <button
        onClick={onBack}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-6 btn btn-link"
        style={{ textDecoration: "none" }}
      >
        <ArrowLeft size={20} />
        &nbsp; Back to Sessions
      </button>

      <div className="bg-white rounded-xl shadow-sm p-6 mb-8 card">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6 card-body">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h3
              className="text-2xl font-bold text-gray-900 mb-4 card-title"
              style={{ fontFamily: "system-ui" }}
            >
              {session.title}
            </h3>
            <div className="flex items-start justify-end">
              {session.videoLink && (
                <a
                  href={session.videoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-50 text-indigo-600 hover:bg-indigo-100 rounded-lg transition-colors btn"
                  style={{
                    backgroundColor: "#1f9ad6",
                    color: "white",
                    fontFamily: "system-ui",
                  }}
                >
                  <Video size={18} />
                  &nbsp;
                  <span
                    className="font-medium"
                    style={{ fontFamily: "system-ui" }}
                  >
                    Watch Recording
                  </span>
                  &nbsp;
                  <LinkIcon size={16} />
                </a>
              )}
            </div>
          </div>
          <div>
            <table>
              <tbody>
                <tr style={{ fontFamily: "system-ui" }}>
                  <td style={{ paddingBottom: 10 }}>
                    <User size={18} />
                    &nbsp;
                    <span className="font-medium">
                      Representative: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;
                    </span>
                  </td>
                  <td style={{ paddingBottom: 10 }}>
                    {session.representative.name}
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: 10 }}>
                    <Briefcase size={18} />
                    &nbsp;
                    <span className="font-medium">Role:</span>
                  </td>
                  <td style={{ paddingBottom: 10 }}>
                    <span>{session.representative.role}</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: 10 }}>
                    <Mail size={18} />
                    &nbsp;
                    <span className="font-medium">Email:</span>
                  </td>
                  <td style={{ paddingBottom: 10 }}>
                    <span className="break-all">
                      {session.representative.email}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: 10 }}>
                    <Users size={18} />
                    &nbsp;
                    <span className="font-medium">Participants:</span>
                  </td>
                  <td style={{ paddingBottom: 10 }}>
                    <span style={{ textTransform: "capitalize" }}>
                      {session.participants.map((p) => p.name).join(", ")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: 10 }}>
                    <Clock size={18} />
                    &nbsp;
                    <span className="font-medium">Duration:</span>
                  </td>
                  <td style={{ paddingBottom: 10 }}>
                    <span>{session.duration} minutes</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: 10 }}>
                    <Calendar size={18} />
                    &nbsp;
                    <span className="font-medium">Session Date:</span>
                  </td>
                  <td style={{ paddingBottom: 10 }}>
                    <span>{format(new Date(session.date), "PPP")}</span>
                    <span className="text-gray-500">
                      (
                      {formatDistanceToNow(new Date(session.date), {
                        addSuffix: true,
                      })}
                      )
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="row pt-4 pb-4"
        style={{
          margin: 0,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div
          className="col-lg-4 col-md-4 col-sm-12 rounded-xl shadow-sm p-6 mb-8 card"
          style={{ backgroundColor: "rgb(239,246,255)", width: "32%" }}
        >
          <div
            className="p-3"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Star
              className="text-blue-600"
              size={20}
              style={{ color: "rgb(59 130 246)" }}
            />
            &nbsp;
            <h5
              className="font-semibold text-blue-900"
              style={{
                margin: 0,
                color: "rgb(30, 58, 138)",
                fontFamily: "system-ui",
              }}
            >
              Rating
            </h5>
          </div>
          <h1
            style={{
              textAlign: "center",
              fontWeight: 800,
              color: "rgb(29 78 216)",
            }}
          >
            {session.rating}/10
          </h1>
          <p
            style={{
              color: "rgb(37 99 235)",
              textAlign: "center",
              fontWeight: 400,
              fontFamily: "system-ui",
            }}
          >
            {session.ratingJustification}
          </p>
        </div>
        <div
          className="col-lg-4 col-md-4 col-sm-12 rounded-xl shadow-sm p-6 mb-8 card"
          style={{ backgroundColor: "rgb(250, 245, 255)", width: "32%" }}
        >
          <div
            className="p-3"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Target size={20} style={{ color: "rgb(147,51,234)" }} />
            &nbsp;
            <h5
              className="font-semibold text-blue-900"
              style={{
                margin: 0,
                color: "rgb(88,28,135)",
                fontFamily: "system-ui",
              }}
            >
              Lead Intent Score
            </h5>
          </div>
          <h1
            style={{
              textAlign: "center",
              fontWeight: 800,
              color: "rgb(126,34,206)",
            }}
          >
            {session.leadIntentScore}/10
          </h1>
          <p
            style={{
              color: "rgb(147,51,234)",
              textAlign: "center",
              fontWeight: 400,
              fontFamily: "system-ui",
            }}
          >
            {session.leadIntentJustification}
          </p>
        </div>

        <div
          className="col-lg-4 col-md-4 col-sm-12 rounded-xl shadow-sm p-6 mb-8 card"
          style={{ backgroundColor: "rgb(240,253,244)", width: "32%" }}
        >
          <div
            className="p-3"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Brain
              className="text-green-600"
              size={20}
              style={{ color: "rgb(22,163,74)" }}
            />
            &nbsp;
            <h5
              className="font-semibold text-blue-900"
              style={{
                margin: 0,
                color: "rgb(20,83,45)",
                fontFamily: "system-ui",
              }}
            >
              Topics Covered
            </h5>
          </div>
          {session.topics && session.topics.length > 0 ? (
            session.topics.map((topic, index) => (
              <span
                key={index}
                className="px-2 py-1 bg-green-100 text-green-700 rounded-full text-sm font-medium"
              >
                {topic}
              </span>
            ))
          ) : (
            <p
              className="text-gray-600"
              style={{
                color: "rgb(75, 85, 99)",
                textAlign: "center",
                fontFamily: "system-ui",
                fontWeight: 600,
              }}
            >
              Topics Unavailable.
            </p>
          )}
        </div>
      </div>

      <div className="row mb-4" style={{ marginLeft: 0, marginRight: 0 }}>
        <div
          className="col-md-12 bg-white rounded-xl shadow-sm mb-8 card"
          style={{ padding: 16 }}
        >
          <h3
            className="text-xl font-semibold text-gray-900 mb-4"
            style={{ color: "rgb(17, 24, 39)", fontFamily: "system-ui" }}
          >
            Summary
          </h3>
          <p
            className="text-gray-700 whitespace-pre-wrap"
            style={{
              color: "rgb(55, 65, 81)",
              fontWeight: 400,
              lineHeight: 1.7,
              fontFamily: "system-ui",
            }}
            dangerouslySetInnerHTML={{
              __html: session.summary
                ? session.summary
                    .split("\n") // Split the content into lines
                    .map((line) => {
                      if (/^\s*-\s+/.test(line)) {
                        // If the line starts with a standalone '-', convert it to <li>
                        return `<li style="color: rgb(55, 65, 81);">${line
                          .replace(/^\s*-\s+/, "")
                          .trim()}</li>`;
                      }
                      return `<p style="color: rgb(55, 65, 81); font-weight:400; line-height: 1.4">${line.trim()}</p>`; // Otherwise, keep it as a paragraph with the color applied
                    })
                    .join("") // Combine the lines back into a single string
                    .replace(
                      /(<li>.*<\/li>)/g,
                      '<ul style="color: rgb(55, 65, 81);">$1</ul>'
                    ) // Wrap all <li> in <ul> with color
                : "",
            }}
          ></p>
        </div>
      </div>

      <div
        className="row mb-4"
        style={{
          marginLeft: 0,
          marginRight: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {/* Strengths Column */}
        <div
          className="col-md-6 bg-white rounded-xl shadow-sm  mb-8 card"
          style={{ padding: 16, width: "49.25%" }}
        >
          <h4
            className="font-medium text-gray-900 mb-2"
            style={{ fontFamily: "system-ui" }}
          >
            Strengths
          </h4>
          {session.sessionStrengths && session.sessionStrengths.length > 0 ? (
            <ul
              className="space-y-2 text-gray-700"
              style={{ paddingLeft: "0" }}
            >
              {session.sessionStrengths.map((strength, index) => (
                <>
                  <li
                    key={index}
                    className="flex items-start gap-2"
                    style={{
                      listStyle: "none",
                      display: "flex",
                      flexDirection: "row",
                    }} // Remove default bullet
                  >
                    <div>
                      <span>
                        <CircleCheckBig style={{ color: "green" }} />
                        &nbsp;
                      </span>
                    </div>
                    <span style={{ fontFamily: "system-ui" }}>
                      {strength.replace(/^\s*-\s*/, "")}
                    </span>
                    {/* Remove leading '-' */}
                  </li>
                  <br />
                </>
              ))}
            </ul>
          ) : (
            <p
              className="text-gray-600"
              style={{ color: "black", fontFamily: "system-ui" }}
            >
              No strengths listed.
            </p>
          )}
        </div>

        {/* Weaknesses Column */}
        <div
          className="col-md-6 bg-white rounded-xl shadow-sm mb-8 card"
          style={{ padding: 16, width: "49.25%" }}
        >
          <h4
            className="font-medium text-gray-900 mb-2"
            style={{ fontFamily: "system-ui" }}
          >
            Weaknesses
          </h4>
          {session.sessionWeaknesses && session.sessionWeaknesses.length > 0 ? (
            <ul
              className="space-y-2 text-gray-700"
              style={{ paddingLeft: "0" }}
            >
              {session.sessionWeaknesses.map((weakness, index) => (
                <>
                  <li
                    key={index}
                    className="flex items-start gap-2"
                    style={{
                      listStyle: "none",
                      display: "flex",
                      flexDirection: "row",
                    }} // Remove default bullet
                  >
                    <div>
                      <span>
                        <CircleX style={{ color: "red" }} />
                        &nbsp;
                      </span>
                    </div>
                    <span style={{ fontFamily: "system-ui" }}>
                      {weakness.replace(/^\s*-\s*/, "")}
                    </span>
                  </li>
                  <br />
                </>
              ))}
            </ul>
          ) : (
            <p
              className="text-gray-600"
              style={{ color: "black", fontFamily: "system-ui" }}
            >
              No weaknesses listed.
            </p>
          )}
        </div>
      </div>

      <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
        <div
          className="col-md-12 bg-white rounded-xl shadow-sm  mb-8 card"
          style={{ padding: 16 }}
        >
          <h4
            className="text-xl font-semibold text-gray-900 mb-4"
            style={{ fontFamily: "system-ui" }}
          >
            Recommendations
          </h4>
          {session.recommendations && session.recommendations.length > 0 ? (
            <ul
              className="space-y-2 text-gray-700"
              style={{ paddingLeft: "0" }}
            >
              {session.recommendations.map((recommendation, index) => (
                <li
                  key={index}
                  className="flex items-start gap-2"
                  style={{ listStyle: "none" }} // Remove default bullet
                >
                  <span>
                    <CircleCheckBig style={{ color: "#1f9ad6" }} />
                    &nbsp;
                  </span>
                  <span style={{ fontFamily: "system-ui" }}>
                    {recommendation.replace(/^\s*-\s*/, "")}
                  </span>
                  <br />
                  <br />
                  {/* Remove leading '-' */}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-600" style={{ color: "black" }}>
              No recommendations provided.
            </p>
          )}
        </div>
      </div>

      <div className="row mt-4" style={{ marginLeft: 0, marginRight: 0 }}>
        <div
          className="col-md-12 bg-white rounded-xl shadow-sm mb-8 card"
          style={{ padding: 16 }}
        >
          <h4
            className="text-xl font-semibold text-gray-900 mb-4"
            style={{ fontFamily: "system-ui" }}
          >
            Flags During The Session
          </h4>
          <FlagSection flags={session.flags} />
        </div>
      </div>

      <div className="row mt-4" style={{ marginLeft: 0, marginRight: 0 }}>
        <div
          className="col-md-12 bg-white rounded-xl shadow-sm mb-8 card"
          style={{ padding: 16 }}
        >
          <h4
            className="text-xl font-semibold text-gray-900 mb-4"
            style={{ color: "black", fontFamily: "system-ui" }}
          >
            Conclusion
          </h4>
          <p
            className="text-gray-700 whitespace-pre-wrap"
            style={{
              color: "black",
              fontWeight: 400,
              lineHeight: 1.7,
              fontFamily: "system-ui",
            }}
          >
            {session.conclusion}
          </p>
        </div>
      </div>
    </div>
  );
}
