// FlagSection.js
import React from 'react';
import { AlertTriangle, AlertCircle, AlertOctagon, CheckCircle } from 'lucide-react';

export function FlagSection({ flags }) {
  const renderFlags = (flagArray, c1, c2, c3, Icon) => {
    if (!flagArray || flagArray.length === 0) return null;

    return (
      <div className={`p-3 rounded mb-1`}>
        {flagArray.map((flag, index) => (
          <div key={index} className="d-flex align-items-start mb-2 p-2" style={{backgroundColor:c1, border:`1px solid ${c3}`, borderRadius:7}}>
            <Icon className="me-2 mt-1" size={20} style={{color:c2, margin:"0px !important"}}/>
            <div>
              <p className="mb-1" style={{color:c2, marginTop:4, fontFamily:"system-ui", fontWeight:500}}>{flag.category}</p>
              <p className="mb-1" style={{ color: 'black', fontWeight:400, fontSize:14, fontFamily:"system-ui" }}>{flag.text}</p>
              <p className="text-muted mb-0" style={{ color: 'black', fontWeight:400, fontSize:14, fontFamily:"system-ui" }}>{flag.context}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {renderFlags(flags.red, '#f8d7da', '#58151c', '#f1aeb5', AlertOctagon)}
      {renderFlags(flags.orange, '#ffedd5', '#c2410c', '#fdba74', AlertCircle)}
      {renderFlags(flags.yellow, '#fef9c3', '#854d0e', '#fde047', AlertTriangle)}
      {renderFlags(flags.green, '#d1e7dd', '#0a3622', '#a3cfbb', CheckCircle)}
    </div>
  );
}
