import React, { useEffect, useState } from "react";
import Logo from "../assets/logo_white_freedom.png";
import Two from "../assets/2.svg";
import Six from "../assets/6.svg";
import Seven from "../assets/7.svg";
import Profile from "../assets/profile.svg";
import Recycle from "../assets/recycle.svg";
import Settings from "../assets/settings.svg";
import Lead from "../assets/lead.svg";
import { Outlet, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Zoom from "../assets/zoom.svg";
export default function ZoomRoot() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false); // Ensure menu is closed on larger screens
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Function to toggle the mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to close the mobile menu
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "row", overflow: "hidden" }}
      >
        {/* Side Menu for Desktop */}
        <div
          className="side-menu"
          style={{ display: screenWidth < 630 && "none" }}
        >
          <div className="top-holder">
            <center>
              <img src={Logo} alt="Logo" className="logo" />
            </center>
          </div>
          <div className="middle-holder">
            <span>
              <Link to="./">
                <img src={Zoom} alt="Zoom Analysis" className="icn" />
                Zoom Analysis
              </Link>
            </span>
          </div>

          <div className="bottom-holder">
            <span
              onClick={() => {
                // Implement logout functionality here
                // Example:
                // axios(config).then(response => { ... })
                window.location.href = "https://kuru.ai";
              }}
            >
              <a
                style={{
                  color: "white",
                  marginLeft: "30px",
                  marginRight: "30px",
                  marginTop: "25px",
                  fontSize: "1rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                  textDecoration: "none",
                  fontWeight: 800,
                  letterSpacing: "1px",
                }}
                href="https://kuru.ai"
              >
                Logout <img src={Seven} alt="Logout" className="icn-2" />
              </a>
            </span>
          </div>
        </div>

        {/* Mobile Menu Overlay */}
        {isMenuOpen && (
          <div className="mobile-menu-overlay">
            <div className="mobile-menu">
              <button className="close-button" onClick={closeMenu}>
                &times;
              </button>
              <div className="mobile-menu-links" style={{display:"flex", flexDirection:"column", justifyContent:"space-between", height:"calc(100% - 70px)"}}>
                <div className="nav-mobile-list">
                  <Link to="./" onClick={closeMenu}>
                    <img src={Two} alt="Dashboard" className="icn" />
                    Analytics
                  </Link>
                  <Link to="./users" onClick={closeMenu}>
                    <img src={Six} alt="User" className="icn" />
                    Contacts
                  </Link>
                  <Link to="./inbox" onClick={closeMenu}>
                    <img src={Recycle} alt="Recycle" className="icn" />
                    Inbox
                  </Link>
                  <Link to="./leads" onClick={closeMenu}>
                    <img src={Lead} alt="Leads" className="icn" />
                    Leads
                  </Link>
                  <Link to="./zoom-analysis" onClick={closeMenu}>
                    <img src={Zoom} alt="Zoom Analysis" className="icn" />
                    Zoom Analysis
                  </Link>
                  <Link to="./settings" onClick={closeMenu}>
                    <img src={Settings} alt="Settings" className="icn" />
                    Settings
                  </Link>
                </div>
                <span
                  onClick={() => {
                    // Implement logout functionality here
                    window.location.href = "/";
                  }}
                  className="logout-link"
                  style={{marginLeft:24}}
                >
                  Logout&nbsp;<img src={Seven} alt="Logout" className="icn-2" />
                </span>
              </div>
            </div>
          </div>
        )}

        {/* Main Section */}
        <div
          className="container-fluid main-section"
          style={{ backgroundColor: "#f2f4f6" }}
        >
          <div
            className="row"
            style={{ backgroundColor: "#fafafa", minHeight: "100vh" }}
          >
            <div className="container-fluid" style={{ paddingTop: 87 }}>
              <div className="row custom-top-nav">
                <div className="col-md-12 top-nav-with-name">
                  <p className="page-heading">
                    {location.pathname === "/dashboard/" ||
                    location.pathname === "/dashboard"
                      ? "Analytics"
                      : location.pathname === "/dashboard/users"
                      ? "Users"
                      : location.pathname === "/dashboard/inbox"
                      ? "Inbox"
                      : location.pathname === "/dashboard/leads"
                      ? "Leads"
                      : location.pathname === "/dashboard/zoom-analysis"
                      ? "Zoom Analysis"
                      : location.pathname === "/dashboard/settings"
                      ? "Settings"
                      : ""}
                  </p>
                  <img
                    src={Profile}
                    alt="profile"
                    className="profile-icon-nav"
                  />
                  <button className="collapsed-nav" onClick={toggleMenu}>
                    <div
                      style={{
                        padding: 2,
                        backgroundColor: "#1f9ad6",
                        marginBottom: 5,
                      }}
                    ></div>
                    <div
                      style={{
                        padding: 2,
                        backgroundColor: "#1f9ad6",
                        marginBottom: 5,
                      }}
                    ></div>
                    <div
                      style={{
                        padding: 2,
                        backgroundColor: "#1f9ad6",
                      }}
                    ></div>
                  </button>
                </div>
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      {/* Styles */}
      <style jsx="true">{`
        .custom-top-nav {
          z-index: 2;
          position: fixed;
          top: 0;
          width: calc(100% - 250px);
          display: flex;
          justify-content: space-between;
          align-items: center;

          background-color: #fafafa;
        }
        .profile-icon-nav {
          display: block;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        .collapsed-nav {
          width: 40px;
          height: 40px;
          border: none;
          background-color: white;
          display: none;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .collapsed-nav div {
          width: 25px;
          height: 3px;
          background-color: #1f9ad6;
          margin: 4px 0;
        }

        /* Mobile Menu Overlay */
        .mobile-menu-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 1000;
          display: flex;
          justify-content: flex-end;
        }
        .mobile-menu {
          width: 250px;
          height: 100%;
          background-color: rgb(31, 154, 214);
          padding: 20px;
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
          animation: slideIn 0.3s forwards;
          position: relative;
        }
        @keyframes slideIn {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(0);
          }
        }
        .close-button {
          position: absolute;
          top: 15px;
          right: 20px;
          background: none;
          border: none;
          font-size: 2rem;
          cursor: pointer;
          color:white
        }
        .mobile-menu-links {
          display: flex;
          flex-direction: column;
          margin-top: 50px;
        }
        .mobile-menu-links a,
        .mobile-menu-links .logout-link {
          display: flex;
          align-items: center;
          padding: 10px 0;
          text-decoration: none;
          color: white;
          font-size: 1.2rem;
          cursor: pointer;
        }
        .mobile-menu-links a img,
        .mobile-menu-links .logout-link img {
          margin-right: 10px;
          width: 24px;
          height: 24px;
        }
        .logout-link {
          color: white;
          font-weight: 800;
          letter-spacing: 1px;
        }

        /* Existing Styles */
        .custom-top-nav {
          z-index: 2;
          position: fixed;
          top: 0;
          width: calc(100% - 250px);
        }
        .profile-icon-nav {
          display: block;
        }
        .collapsed-nav {
          width: 40px;
          border: 0;
          background-color: white;
          display: none;
        }

        @media only screen and (max-width: 768px) {
          .side-menu {
            display: none;
          }
          .main-section {
            margin-left: 0px;
          }
          .custom-top-nav {
            width: 100%;
          }
          .profile-icon-nav {
            display: none;
          }
          .collapsed-nav {
            display: flex;
          }
        }
        .nav-mobile-list a {
          color: white
        }
        
      `}</style>
    </>
  );
}
