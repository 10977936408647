import React, { useState } from "react";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      alert("Please fill in both username and password fields.");
      return;
    }

    if (username === "admin@freedompropertyinvestors.com.au" && password === "admin@123") {
      window.location.href = "https://kuru.ai/dashboard";
    } else if(username === "zoom@freedompropertyinvestors.com.au" && password === "zoom@123"){
      window.location.href = "https://zoom-analysis.kuru.ai/zoom-dashboard";
    }else {
      alert("Check Username and Password");
    }
  };

  return (
    <div className="login-page" style={{minHeight: "100vh", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", backgroundColor:"#d1e2ee"}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-4">
            <div className="card border-0 shadow-lg">
              <div className="card-body p-5">
                <div className="text-center mb-4">
                  <div className="logo-container mb-3">
                    <svg
                      width="240"
                      height="50"
                      viewBox="0 0 240 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="10" y="8" width="36" height="6" fill="#0288D1"></rect>
                      <rect x="10" y="22" width="36" height="6" fill="#0288D1"></rect>
                      <rect x="10" y="36" width="36" height="6" fill="#0288D1"></rect>
                      <text
                        x="58"
                        y="30"
                        fill="#0288D1"
                        style={{
                          fontSize: "28px",
                          fontWeight: "bold",
                          fontFamily: "Arial",
                        }}
                      >
                        FREEDOM
                      </text>
                      <text
                        x="58"
                        y="42"
                        fill="#0288D1"
                        style={{
                          fontSize: "11px",
                          letterSpacing: "0.5px",
                          fontFamily: "Arial",
                        }}
                      >
                        PROPERTY INVESTORS
                      </text>
                    </svg>
                  </div>
                  <h5 className="text-secondary" style={{ fontFamily:"system-ui", fontWeight:600, fontSize:16}}>Freedom Performance Manager</h5>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      placeholder="Email *"
                      required
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control form-control-lg"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn position-absolute end-0 top-50 translate-middle-y border-0"
                        style={{ backgroundColor: "transparent" }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={`bi ${
                            showPassword ? "bi-eye-slash" : "bi-eye"
                          }`}
                        ></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="rememberMe"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="rememberMe"
                      >
                        Remember Me
                      </label>
                    </div>
                    <a
                      href="#"
                      className="text-decoration-none"
                      style={{ color: "rgb(2, 136, 209)" }}
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100 py-2"
                    style={{
                      backgroundColor: "rgb(2, 136, 209)",
                      borderColor: "rgb(2, 136, 209)",
                    }}
                  >
                    Login
                  </button>
                </form>
              </div>
            </div>
            <div className="text-center mt-4 text-secondary small">
              <p className="mb-1" style={{color:"#7a838a", fontFamily:"system-ui", fontWeight:400, fontSize:14}}>
                © 2024 - Freedom Zoom Analysis - v1.0.2
              </p>
              <p className="mb-0" style={{color:"#7a838a", fontFamily:"system-ui", fontWeight:400, fontSize:14}}>
                Crafted by{" "}
                <a
                  href="#"
                  className="text-decoration-none"
                  style={{ color: "rgb(2, 136, 209)" }}
                >
                  Freedom Property Investors
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
